import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import {Tooltip } from "reactstrap";
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const config = require('../../config.json');

export default function ImageDisplay(props) {
    return (
        <div className="card box" style={{margin:'10px'}} key={props.doctorName}>
            {props.doctorName}
                        <img src={`${config.s3.imageInvokeURL}${props.imageSrc}?${new Date().getTime()}`} alt="1" height="200" width="200"/> <br />
                        {/*<button onClick={(event)=>props.handleDeleteImage(event, props.imageSrc)}>Remove</button>*/}
                        <FontAwesomeIcon id="TooltipExample" icon={faTrash} className="me-2" onClick={(event)=>props.handleDeleteImage(event, props.imageSrc)}/>
        </div>
    )
}
