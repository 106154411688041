import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faHome, faSearch, faEllipsisH, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Table } from '@themesberg/react-bootstrap';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert, Label, FormGroup, Input, Card  } from "reactstrap";
import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';
import axios from "axios";
import ReactToPrint from "react-to-print";
import { Auth } from "aws-amplify";
import { CSVLink } from "react-csv";
import { downloadSubscriberHeaders, journalCategory } from "../../ProData";
import {Helmet} from 'react-helmet-async';
import SubscriberForm from "./SubscriberForm";
import PaymentForm from "../payment/PaymentForm";

const config = require('../../config.json');

export default function SearchPage() {
  const { loggedUser, alertClose, alertDetails, setAlertDetails, validateLogin, subscriberFormType, setSubscriberFormType, setsubscriberDetails} = useContext(ProductContext);
  const csvLinkEl = React.createRef();
  //const [applicationDetails, setApplicationDetails]=useState([]);
  const [searchTopic, setSearchTopic] = useState('SubscriberID');
  const [searchValue, setSearchValue] = useState('');
  const [loaded, setLoaded] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [displayResults, setDisplayResults] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [newCollapsed, setNewCollapsed] = useState(true);
  const componentRef = React.useRef(null);
  const componentModelRef = React.useRef(null);
  const [isLabelViewChecked, setIsLabelViewChecked] = useState(false);
  const [viewOption,setViewOption] = useState("All");
  const [isActiveView, setIsActiveView] = useState(true);

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      //processBatchYear();
    }else{
    validateLogin();
    alertClose();
    }
    return()=>{
      alertClose();
    }
   // console.log(batchYear);
  },[loggedUser])

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);

  const reactToPrintContentModal = React.useCallback(() => {
    return componentModelRef.current;
  }, [componentModelRef.current]);

  const reactToPrintTriggerModal = React.useCallback(() => {
    return <Button   >Print</Button>;
  }, []);

  const handleApplicationView=(event, item, action)=>{
    event.preventDefault();
    try{
      setSubscriberFormType(action);
      setsubscriberDetails(item);
      toggle();
    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    }
  }

  const handlePaymentForm=(event, item, action)=>{
    event.preventDefault();
    try{
      setSubscriberFormType(action);
      setsubscriberDetails(item);
      newToggle();
    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    }

  }

  const handleDownload =async(event)=>{
    event.preventDefault();
    csvLinkEl.current.link.click();
  }

  const toggle = () => {
    //   console.log("clicked");
         setCollapsed(!collapsed);
  }

  const newToggle = () => {
  //   console.log("clicked");
        setNewCollapsed(!newCollapsed);
  }

  const handleSearch=async()=>{
    //event.preventDefault();
    try{
      if(searchValue===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Search Value is empty Enter the search input and then hit Search button!"
      });  
      }else{
        setLoaded(false);
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;  
        var tsearchValue=searchValue
        if(searchTopic==="SubscriberName"){
          tsearchValue=searchValue.toUpperCase();
          setSearchValue(tsearchValue);
        }
      await axios.get(`${config.api.invokeUrl}/subscribers/globalsearch?fetchKey=${tsearchValue}&fetchOption=${searchTopic}`, {
        headers: {
          Authorization: access_token,
          'x-api-key':config.api.key
        }
      }).then(async(response) => {
        //console.log("response : ", response);
        if(response.data.length>0)
        {
            //console.log("order status")
            setSearchResults(response.data);
            //setDisplayResults(response.data);
            processResults(response.data, viewOption, isActiveView);

        }
        setAlertDetails({
          ...alertDetails,
          style:"success",
          open:true,
          message: response.data.length + " - Subscriber's records found!"
      });  
        setLoaded(true);

    }).catch((error)=>{
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
  })
}
    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    });        
    setLoaded(true);
    }
  }

  const processResults=(rawData, type, activeView)=>{
    //const rawData=searchResults;
    var tempDisplayResults=[];
    if(type==="Email"){
      const filteredData=rawData.filter(item=>item.emailID!=="NA");
      tempDisplayResults=filteredData;
      //setDisplayResults(filteredData);
      setAlertDetails({
        ...alertDetails,
        style:"success",
        open:true,
        message: filteredData.length + " - Subscriber's records Filtered by Email"
    });  

      //console.log("filtered data : ", filteredData);
    }else if(type==="No Email"){
      const filteredData=rawData.filter(item=>item.emailID==="NA");
      tempDisplayResults=filteredData;
      //setDisplayResults(filteredData);
      setAlertDetails({
        ...alertDetails,
        style:"success",
        open:true,
        message: filteredData.length + " - Subscriber's records Filtered by No Email Address!"
    });  
      //console.log("filtered data : ", filteredData);
    }else{
      tempDisplayResults=rawData;
      //setDisplayResults(rawData);
      setAlertDetails({
        ...alertDetails,
        style:"success",
        open:true,
        message: rawData.length + " - Subscriber's records!"
    });  
    }
    if(activeView){
      const filteredData=tempDisplayResults.filter(item=>item.subStatus==="Active");
      tempDisplayResults=filteredData;
      //setDisplayResults(filteredData);
      setAlertDetails({
        ...alertDetails,
        style:"success",
        open:true,
        message: filteredData.length + " - Subscriber's records Filtered by Active Accounts"
      });  
    }
    setDisplayResults(tempDisplayResults);
  }

  const handleLabelViewCheck =()=>{
    //event.preventDefault();
    setIsLabelViewChecked(!isLabelViewChecked);
  }

  const handleViewOptionChange=async(type)=>{
    await processResults(searchResults, type, isActiveView);
    setViewOption(type);

  }

  const handleActiveSwitchChange=async()=>{
    await processResults(searchResults, viewOption, !isActiveView);
    setIsActiveView(!isActiveView);
  }

  return (
<>
    <Helmet>
      <title>Subscriber Search - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="Subscriber Search - Admin Office - VJDepth - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="Subscriber Search - Admin Office - VJDepth - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
    </Helmet>

        <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>
        {/*View*/}
        <div >
          <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%', maxWidth:'800px'}} >
          <div  ref={componentModelRef}>
             <ModalHeader  toggle={toggle}>
               Registration Form - {subscriberFormType}
                    </ModalHeader>
                    <ModalBody >    
                        <SubscriberForm handleSearch={handleSearch} toggle={toggle} /> 
                    </ModalBody>
                    <ModalFooter>
            {' '}
            <ReactToPrint
        content={reactToPrintContentModal}
        documentTitle="AwesomeFileName"
        
        trigger={reactToPrintTriggerModal}
      />
            <Button onClick={toggle}>
                Close
            </Button>
            </ModalFooter>
          </div>
                   
        </Modal>
        </div>

        {/*Payment*/}
        <Modal toggle={newToggle} isOpen={!newCollapsed} style={{top:'10%', maxWidth:'800px'}}>
            <ModalHeader toggle={newToggle}>
            Payment - Add
            </ModalHeader>
            <ModalBody>    
            <div>
            <PaymentForm formType={"New"} newToggle={newToggle}/> 
              </div>
            </ModalBody>
            <ModalFooter>
            {' '}
            <Button onClick={newToggle}>
                Close
            </Button>
            </ModalFooter>
          </Modal>


      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Subscriber Details</Breadcrumb.Item>
            <Breadcrumb.Item active>Search</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Search Subscribers</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <ReactToPrint
            content={reactToPrintContent}
            documentTitle="student"
            trigger={reactToPrintTrigger}
          />
          <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
          <CSVLink
              headers={downloadSubscriberHeaders}
              filename="download.csv"
              data={searchResults}
              ref={csvLinkEl}
            />
          </ButtonGroup>
        </div>
      </div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
      <div className="d-block mb-4 mb-md-0">
      </div>
      <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <FormGroup
            inline
            check
          >
            <Input type="checkbox" defaultChecked={false} checked={isLabelViewChecked} onChange={handleLabelViewCheck}/>
            <Label check>
              Label Print View
            </Label>
          </FormGroup>
          <FormGroup inline check >
          <Label >All</Label>
        <Input
          type="radio"
          name="radioView"
          value="All"
          checked={viewOption==="All"}
          onClick={() => {
            handleViewOptionChange("All")
          }}
        />
      </FormGroup>
          <FormGroup inline check >
          <Label >Email</Label>
        <Input
          type="radio"
          name="radioView"
          value="Email"
          checked={viewOption==="Email"}
          onClick={() => {
            handleViewOptionChange("Email")
          }}
        />
      </FormGroup>
      <FormGroup inline check>
          <Label check>No-Email
        <Input
          type="radio"
          name="radioView"
          value="No Email"
          checked={viewOption==="No Email"}
          onClick={() => {
            handleViewOptionChange("No Email");
          }}
        />
        </Label>
        </FormGroup>
        </ButtonGroup>
        </div>
        </div>

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
      <div className="d-block mb-4 mb-md-0">
      </div>
      <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <FormGroup inline switch >
          <Label >Active</Label>
        <Input
          type="switch"
          name="switchView"
          value="Active"
          checked={isActiveView}
          onClick={() => {
            handleActiveSwitchChange(isActiveView)
          }}
        />
      </FormGroup>
        </ButtonGroup>
        </div>
        </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-start align-items-center">
        <Col md={4} className="mb-3">
              <Form.Group id="searchstud">               
                <Form.Select defaultValue="SubscriberID" value={searchTopic} onChange={(event)=>{setSearchTopic(event.target.value)}}>
                <option value="SubscriberID">Subscriber ID</option>
                <option value="SubscriberName">Subscriber Name</option>
                <option value="EmailID">Email</option>
                  <option value="Category">Category</option>
                </Form.Select>
              </Form.Group>
            </Col>
          <Col  className="mb-3" md={8}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              {searchTopic==="Category"?           
              <Form.Select value={searchValue} autoFocus
                  onChange={(event)=>{setSearchValue(event.target.value)}}>
                  {journalCategory.map(item => {
                    return (
                    <option key={item.id} value={item.category}>{item.category} - {item.details}</option>
                    );
                  })}
            </Form.Select>:
            <Form.Control type="text"  autoFocus placeholder="Search" value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}/>}
              <Button variant="primary" type="submit" onClick={(event)=>{handleSearch()}}>Submit</Button>
            </InputGroup>
          </Col>
        </Row>
      </div>

     {isLabelViewChecked? <Table ref={componentRef} hover responsive>
             <thead>
              <tr>
                <th>#</th>
                <th>Name & Address</th>
              </tr>
            </thead>
            <tbody>
            {displayResults.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td><div><>Name: {item.title}.{item.subscriberName}</><br />
                     <>House: {item.houseName}</><br />
                     <>Address: {item.addressStreet}, <br />
                     {item.addressCity}, {item.addressState}</><br />
                     <>Pincode: {item.addressPincode}</><br />
                     <>Country: {item.country}</><br />
                     <>Contact No: {item.areaCode}-{item.contactNo}</></div></td>
            </tr>)}
            </tbody>
      </Table>:<Table ref={componentRef} hover responsive>
             <thead>
              <tr>
                <th>#</th>
                <th>Subscriber ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>End Date</th>
                <th>Status</th>
               <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {displayResults.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.subscriberID}</td>
            <td><div><>Name: {item.title}.{item.subscriberName}</><br />
                     <>House: {item.houseName}</><br />
                     <>Address: {item.addressStreet}, <br />
                     {item.addressCity}, {item.addressState}</><br />
                     <>Pincode: {item.addressPincode}</><br />
                     <>Country: {item.country}</><br />
                     <>Contact No: {item.areaCode}-{item.contactNo}</></div></td>
            <td>{item.emailID}</td>
            <td>{item.endDate}</td>
            <td>{item.subStatus==="Cancelled"?<p style={{backgroundColor:"blue", borderRadius:"10px", textAlign:"center", color:"white"}}>Cancelled</p>:
            item.subStatus==="Active"?<p style={{backgroundColor:"green", borderRadius:"10px", textAlign:"center", color:"white"}}>Active</p>:
            <p style={{backgroundColor:"red", borderRadius:"10px", textAlign:"center", color:"white"}}>Expired</p>}</td>
           <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item, "View")}}>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{handleApplicationView(event, item, "Edit")}}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit Details
              </Dropdown.Item>
              <Dropdown.Item  onClick={(event)=>{handlePaymentForm(event, item, "Add")}}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Add Payment
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </td>
            </tr>)}
            </tbody>
      </Table>}
    </>
  )
}
