import React, { useState, useContext , useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
//import { faFacebookF, faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Form, Card, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import {Alert} from "reactstrap";
import { Auth } from "aws-amplify";
import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import { ProductContext } from "../../ProductProvider";
import {Helmet} from 'react-helmet-async';

export default function Signin() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showNewPass, setShowNewPass] = useState(false);
  const [newPassword, setNewPassword]=useState('');
  const [user, setUser] = useState("");
  const history=useHistory();
  //const [alertDetails, setAlertDetails] = useState({style:"", open:false, message:""});
  const { loggedUser, setLoggedUser, alertClose, alertDetails, setAlertDetails } = useContext(ProductContext);

useEffect(() => {
  alertClose();
}, [])


  const handleSignIn = async(event)=>{
    event.preventDefault();
    //console.log("inpts : ", username, password);
    try{
      if(username===""||password===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Username and password cannot be empty"
      }); 
      }else{
    await Auth.signIn(username, password)
    .then((user)=>{
      //console.log("user : ", user);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setUser(user);
        setShowNewPass(true);
        setAlertDetails({
          ...alertDetails,
          style:"information",
          open:true,
          message:"Set up your New password!"
      }); 
      }else{  
        var tadmin=false;
         if(user.attributes['profile']==="Admin"){
          tadmin=true;
         }
        setLoggedUser({...loggedUser,
          name:user.attributes['name'],
          email:user.attributes['email'],
          profile:user.attributes['profile'],
          DOB:user.attributes['birthdate'],
          website:user.attributes['website'],
          nickname:user.attributes['nickname'],
          gender:user.attributes['gender'],
          nickname:user.attributes['family_name'],
          phone_number:user.attributes['phone_number'],
          isAuthenticated:true,
          isAdmin:tadmin
    });
    history.push(Routes.DashboardOverview.path);
    //   navigate("/mydashboard");
  }
})
.catch((error) =>{
  setAlertDetails({
    ...alertDetails,
    style:"warning",
    open:true,
    message:"Error Occured - " + error.message
}); 
})
}
}
catch(error){
setAlertDetails({
  ...alertDetails,
  style:"warning",
  open:true,
  message:"Error Occured - " + error.message
}); 
}
}

const handleChangePassword =async(event)=>{
  event.preventDefault();
  try{
    await Auth.completeNewPassword(
      user,        
      newPassword
  ).then(localeuser => {
      // at this time the user is logged in if no MFA required
      //console.log(localeuser);
     var tadmin=false;
         if(localeuser.challengeParam.userAttributes['profile']==="Admin"){
          tadmin=true;
         }
      setLoggedUser({...loggedUser,
        name:localeuser.challengeParam.userAttributes['name'],
        email:localeuser.challengeParam.userAttributes['email'],
        DOB:localeuser.challengeParam.userAttributes['birthdate'],
        profile:localeuser.challengeParam.userAttributes['profile'],
        nickname:localeuser.challengeParam.userAttributes['family_name'],
        website:localeuser.challengeParam.userAttributes['website'],
        //nickname:localeuser.challengeParam.userAttributes['nickname'],
        gender:localeuser.challengeParam.userAttributes['gender'],
        phone_number:localeuser.challengeParam.userAttributes['phone_number'],
        isAuthenticated:true,
        isAdmin:tadmin
    });
    history.push(Routes.DashboardOverview.path);
  }).catch(e => {
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message:"Error Occured - " + e.message
    }); 
  });

  }catch(error){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message:"Error Occured - " + error.message
    }); 
  }
}

  return (
    <main>
      <Helmet>
      <title>Sign In - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="Sign In - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="Sign In - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
    </Helmet>

      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <p className="text-center">
          <a href="">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to homepage
              </a>
          </p>
          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                <h3 className="mb-0">VIDYAJYOTI JOURNAL - Admin Office</h3>
                </div>
                <Alert color={alertDetails.style} fade={true} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
  </Alert>
                <Form className="mt-4">
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="email" placeholder="example@company.com" 
                      value={username} onChange={(event)=>{setUsername(event.target.value)}}/>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                  {!showNewPass?  <Form.Group id="password" className="mb-4">
                      <Form.Label>Your Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control required type="password" placeholder="Password" 
                        value={password} onChange={(event)=>{setPassword(event.target.value)}}/>
                      </InputGroup>
                    </Form.Group>:
                    <Form.Group id="newpassword" className="mb-4">
                      <Form.Label>New Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control required type="password" placeholder="New Password" 
                        value={newPassword} onChange={(event)=>{setNewPassword(event.target.value)}}/>
                      </InputGroup>
                    </Form.Group>}
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      {/*<Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" />
                        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember me</FormCheck.Label>
                        </Form.Check>*/}
                      <Card.Link as={Link} to={Routes.ForgotPassword.path} className="small text-end">Lost password?</Card.Link>
                    </div>
                  </Form.Group>
                  {!showNewPass?<Button variant="primary" type="submit" className="w-100" onClick={(event)=>{handleSignIn(event)}}>
                    Sign in
                  </Button>:
                  <Button variant="primary" type="submit" className="w-100" onClick={(event)=>{handleChangePassword(event)}}>
                    Change Password
                  </Button>}
                
                </Form>

               {/*} <div className="mt-3 mb-4 text-center">
                  <span className="fw-normal">or login with</span>
                </div>
                <div className="d-flex justify-content-center my-4">
                  <Button variant="outline-light" className="btn-icon-only btn-pill text-facebook me-2">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </Button>
                  <Button variant="outline-light" className="btn-icon-only btn-pill text-twitter me-2">
                    <FontAwesomeIcon icon={faTwitter} />
                  </Button>
                  <Button variant="outline-light" className="btn-icon-only btn-pil text-dark">
                    <FontAwesomeIcon icon={faGithub} />
                  </Button>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Not registered?
                    <Card.Link as={Link} to={Routes.Signup.path} className="fw-bold">
                      {` Create account `}
                    </Card.Link>
                  </span>
  </div>*/}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  )
}
