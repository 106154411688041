import React,{useState, useEffect, useContext} from 'react'
// Import the main component
import { Viewer } from '@react-pdf-viewer/core'; // install this library
// Plugins
//import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; // install this library
//import { searchPlugin } from '@react-pdf-viewer/search';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
//import { ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
//import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
// Worker
import { Worker } from '@react-pdf-viewer/core'; // install this library
import { Card} from 'react-bootstrap';
//import { pdfJournalData } from '../../ProData';
import { Alert} from 'reactstrap';
import {Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { Col, Row, Button, Form, ButtonGroup, Breadcrumb, InputGroup,  Dropdown, Table} from '@themesberg/react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faHome, faSearch, faBackward, faEdit, faList, faEllipsisH, faThLarge } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';
import { ProductContext } from '../../ProductProvider';
import Preloader from "../../components/Preloader";
import { Auth } from "aws-amplify";
import { faReadme } from '@fortawesome/free-brands-svg-icons';
import AddPDF from './AddPDF';
//import pope from '../../assets/img/popefrancis.jpg';
const config = require('../../config.json');

export const ViewPDF = () => {
  // for onchange event
  const { alertClose, alertDetails, setAlertDetails, pdfFormType, setPDFFormType, setPDFDetails, loggedUser, validateLogin } = useContext(ProductContext);
  const [pdfFile, setPdfFile]=useState(null);
  const [showPDF, setShowPDF]=useState(false);
  const [pdfJournalDetails, setPDFJournalDetails] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [collapsed, setCollapsed] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [searchTopic, setSearchTopic] = useState('JournalID');
  const [cardView, setCardView] = useState(true); 
  // Create new plugin instance
  //const defaultLayoutPluginInstance = defaultLayoutPlugin();
  //const searchPluginInstance = searchPlugin();
  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
  const componentRef = React.useRef(null);

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      //processBatchYear();
    }else{
    validateLogin();
    alertClose();
    }
    return()=>{
      alertClose();
    }
   // console.log(batchYear);
  },[loggedUser])

  const toggle = () => {
    //   console.log("clicked");
         setCollapsed(!collapsed);
  }

  const fetchJournalDetails=async(event)=>{
    event.preventDefault();
    try{
      if(searchValue===""){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Search Value cannot be Empty"
      }); 
      }else{
      setLoaded(false);
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      //const clientID="VJJournal";
      //const searchOption="Status";
      //const searchValue="Active";
      await axios.get(`${config.api.invokeUrl}/journal/search?searchOption=${searchTopic}&searchValue=${searchValue}`,{
          headers: {
            Authorization: access_token,
            'x-api-key': config.api.key
          }}, 
      ).then((response)=>{
        //console.log("Response : ", response);
        setPDFJournalDetails(response.data);
          setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: response.data.length + " - " + searchTopic + " Journals Found"
          });  
          //console.log(response);
          setLoaded(true);
      }).catch((error)=>{
          //console.log(error);
          setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Error Occured - " + error.message
          }); 
          setLoaded(true);
          //setDispMsg(error.message);
      })
      }
  }catch(error){
      setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      }); 
      setLoaded(true);
  }
  }
  

  const transform = (slot) => ({
      ...slot,
      Download: () => <></>,
      DownloadMenuItem: () => <></>,
      Open: () => <></>,
      OpenMenuItem: () => <></>,
      Print: () => <></>,
      PrintMenuItem: () => <></>,
      //EnterFullScreen: () => <></>,
      //EnterFullScreenMenuItem: () => <></>,
      SwitchTheme: () => <></>,
      SwitchThemeMenuItem: () => <></>,
  });

const handleFetchPDF = async(e, item) => {
    e.preventDefault();
    //console.log("fetch : ", item);
    try{
      const fetchFile=item.journalID+".pdf";
      //console.log("fetch file :", fetchFile);
      const bucketName='docs.vidyajyotijournal.com/pdfs';
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      await axios.get(`${config.api.invokeUrl}/presigned/downloader?fileName=${fetchFile}&bucketName=${bucketName}`,{
        headers: {
          Authorization: access_token,
          'x-api-key': config.api.key
        }}, 
     ).then(async(response)=>{
          //console.log("Response : ", response);
          setPdfFile(response.data.downloadURL);
          setShowPDF(true); 

          /*await axios.get(response.data.downloadURL,{
            responseType:'arraybuffer',
            headers: {
              "Content-Type": "application/json",
              "Accept":"application/pdf",
              //"content-disposition": "attachment; filename=test.pdf",
              "access-control-allow-origin" : "*",
            }
          })
          .then(function(pdfresponse){
           console.log("pdf response : ", pdfresponse);
           let blob = new Blob([pdfresponse.data], {type: 'application/pdf'});
           const link = document.createElement('a');
           link.href = URL.createObjectURL(blob);
           console.log("link.href : ", link);
          // setPdfFile(pdfresponse.data);
          // setShowPDF(true); 
 
          })*/
      }).catch((error)=>{
          console.log("Error1 : ", error);
          //setDispMsg("Error Occured - ", error.message);
      })        
    }catch(error){
        console.log("Error2 : ", error);
    }
}

const handleBack=(e)=>{
  e.preventDefault();
  setPdfFile('');
  setShowPDF(false); 
}

const handlePageChange = (e) => {
  localStorage.setItem('current-page', `${e.currentPage}`);
};

const handleSwitchTheme = (theme) => {
  localStorage.setItem('current-theme', theme);
};

const handleEditPDF=(e, item,action)=>{
e.preventDefault();
try{
  setPDFFormType(action);
  setPDFDetails(item);
  toggle();
}catch(error){
  setAlertDetails({
    ...alertDetails,
    style:"warning",
    open:true,
    message:"Error Occured - " + error.message
});        
}
}

const handleSearchSelection=(event)=>{
  setSearchTopic(event.target.value);
  if(event.target.value==="JournalStatus"){
    setSearchValue("Active");
  }else{
    setSearchValue('');
  }
}

const handleLayoutChange=(event, mode)=>{
  event.preventDefault();
  if(mode==="List"){
    setCardView(false);
  }else{
    setCardView(true);
  }
}



  return (
    <div className='container'>
       <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>

        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Publications</Breadcrumb.Item>
            <Breadcrumb.Item active>Manage</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Publications</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"> 
          <ButtonGroup>
          <Button variant="outline-primary" size="sm" onClick={(event=>handleLayoutChange(event, "List"))}><FontAwesomeIcon icon={faList} /></Button>
         <Button variant="outline-success" size="sm" onClick={(event=>handleLayoutChange(event, "Card"))} ><FontAwesomeIcon icon={faThLarge} /></Button>
          </ButtonGroup>
  </div>
      </div>
     

         {/*View*/}
         <div >
          <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%', maxWidth:'800px'}} >
          <div>
             <ModalHeader  toggle={toggle}>
               Registration Form - {pdfFormType}
                    </ModalHeader>
                    <ModalBody >    
                        <AddPDF toggle={toggle} /> 
                    </ModalBody>
                    <ModalFooter>
            {' '}
            
            <Button onClick={toggle}>
                Close
            </Button>
            </ModalFooter>
          </div>
                   
        </Modal>
        </div>

        <div className="table-settings mb-4">
        <Row className="justify-content-start align-items-center">
        <Col md={4} className="mb-3">
              <Form.Group id="searchstud">               
                <Form.Select defaultValue="JournalID" value={searchTopic} onChange={(event)=>{handleSearchSelection(event)}}>
                <option value="JournalID">Journal ID</option>
                <option value="AuthorName">Author Name</option>
                <option value="JournalStatus">Status</option>
                </Form.Select>
              </Form.Group>
            </Col>
          <Col  className="mb-3" md={8}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              {searchTopic==="JournalStatus"?           
              <Form.Select value={searchValue} 
                  onChange={(event)=>{setSearchValue(event.target.value)}}>
                  <option value="Active">Active</option>
                  <option value="Archive">Archive</option>
                  <option value="In-Active">In-Active</option>                    
            </Form.Select>:
            <Form.Control type="text" placeholder="Search" value={searchValue} onChange={(event)=>{setSearchValue(event.target.value)}}/>}
              <Button variant="primary" type="submit" onClick={(event)=>{fetchJournalDetails(event)}}>Submit</Button>
            </InputGroup>
          </Col>
        </Row>
      </div>


{cardView ? <Row style={{padding:"5px"}}> 


    {!showPDF && pdfJournalDetails.map((item,index)=><>
                   
      <Col  md={4}>
            <Card key={index}>
                <Card.Img style={{height:'200px'}}  variant="top" src={`${config.s3.imginvokeUrl}${item.journalID}.jpeg`} />
                <Card.Body>
                <Card.Title style={{fontSize:"14px", height:'80px', textAlign:"center"}}>{item.journalName} 
                <p style={{fontSize:"12px", height:'05px'}}><i>{item.authorName} </i></p>
                <p style={{fontSize:"12px", height:'10px'}}><i>{new Date(item.publishDate).toLocaleDateString('en-us', { year:"numeric", month:"long"})}</i></p></Card.Title>
                <Card.Text style={{fontSize:"12px", height:"130px", overflow:'hidden'}}>
                    {item.description}
                </Card.Text>
                </Card.Body>
                <Card.Footer>
                    <small className="text-muted">{item.rating}</small>
                    <div className='d-flex justify-content-between'>
                    
                      <FontAwesomeIcon icon={faEdit} onClick={event=>{handleEditPDF(event, item,"Edit")}}/>
                      
                      <FontAwesomeIcon icon={faReadme} onClick={event=>{handleFetchPDF(event, item)}}/>
                     
                    </div>
                    <div >
                     
                    </div>
                   <div>
                     
                   </div>
                   
                </Card.Footer>
            </Card>
        
       </Col>
    </>)}
    
     </Row>:
     <>
      {pdfJournalDetails.length>0 && <Table ref={componentRef} hover responsive>
      <thead>
       <tr>
         <th>#</th>
         <th>Journal ID</th>
         <th>Title</th>
         <th>Author</th>
         <th>Publish Date</th>
         <th>Status</th>
        <th>Action</th>
       </tr>
     </thead>
     <tbody>
     {pdfJournalDetails.map((item, index)=>
     <tr key={index}>
     <th scope="row">{index+1}</th>
     <td>{item.journalID}</td>
     <td>{item.journalName}</td>
     <td>{item.authorName}</td>
     <td>{new Date(item.publishDate).toLocaleDateString('en-us', { year:"numeric", month:"long"})}</td>
     <td>{item.journalStatus==="Archive"?<p style={{backgroundColor:"blue", borderRadius:"10px", textAlign:"center", color:"white"}}>Archive</p>:
     item.journalStatus==="Active"?<p style={{backgroundColor:"green", borderRadius:"10px", textAlign:"center", color:"white"}}>Active</p>:
     <p style={{backgroundColor:"red", borderRadius:"10px", textAlign:"center", color:"white"}}>In-Active</p>}</td>
    <td>
    <Dropdown as={ButtonGroup}>
     <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
       <span className="icon icon-sm">
         <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
       </span>
     </Dropdown.Toggle>
     <Dropdown.Menu>
       <Dropdown.Item  onClick={(event)=>{handleEditPDF(event, item,"View")}}>
         <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
       </Dropdown.Item>
       <Dropdown.Item  onClick={(event)=>{handleEditPDF(event, item,"Edit")}}>
         <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit Details
       </Dropdown.Item>
       <Dropdown.Item  onClick={(event)=>{handleFetchPDF(event, item)}}>
         <FontAwesomeIcon icon={faReadme} className="me-2" /> View Journal
       </Dropdown.Item>

     </Dropdown.Menu>
   </Dropdown>
     </td>
     </tr>)}
     </tbody>
</Table>}</>}

    {showPDF && <div style={{height:800}}>
      <div>
      <FontAwesomeIcon icon={faBackward} onClick={event=>{handleBack(event)}}/> Go Back
      </div>


        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">

        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
          <Viewer fileUrl={pdfFile}  onPageChange={handlePageChange} plugins={[toolbarPluginInstance]} theme="auto"
          onSwitchTheme={handleSwitchTheme}
             />
      </Worker>

      </div>}
      {/*<div
            className="rpv-core__viewer"
            style={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <div
                style={{
                    alignItems: 'center',
                    backgroundColor: '#eeeeee',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    padding: '0.25rem',
                }}
            >
                <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
            </div>
            <div
                style={{
                    flex: 1,
                    overflow: 'hidden',
                }}
            ><Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer fileUrl={url} plugins={[toolbarPluginInstance]} />
                </Worker>
            </div>
            </div>*/}

    </div>
  )
}

export default ViewPDF;