import React, { useContext, useEffect} from "react";
import { Card, CardBody } from 'reactstrap';
import logo from '../../assets/img/logo-new.png';
import { ProductContext } from '../../ProductProvider';
import {Helmet} from 'react-helmet-async';

export default function WelcomeDash() {

  const { alertClose, loggedUser, validateLogin } = useContext(ProductContext);

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
    }else{
      validateLogin();
    }
    return()=>{
      alertClose();
    }
  },[loggedUser])

  return (
    <div className='container text-center'>
    <Helmet>
      <title>Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
    </Helmet>
        <Card>
            <CardBody>
                <h3 className='text-info' style={{fontFamily:'sans-serif'}}>WELCOME <br/>Vidyajyoti Journal of Theological Reflection</h3>
                <img src={logo} alt='logo' width="500px" />
                <h1>Admin Portal</h1>
            </CardBody>
        </Card>
    </div>
  )
}
