import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";
// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
//import Footer from "../components/Footer";
import Preloader from "../components/Preloader";
//auth
import Signin from './auth/Signin';
//import Signup from './auth/Signup';
import ForgotPassword from './auth/ForgotPassword';
import ResetPassword from './auth/ResetPassword';
import NotFoundPage from './auth/NotFound';
import ServerError from "./auth/ServerError";
import ImageViewer from './ImageEditor/ImageViewer';
import AccountMgmt from './subscription/AccountMgmt';
import SearchPage from './subscription/SearchPage';
import ManageSubscription from './subscription/ManageSubscription';
import SubscriberForm from './subscription/SubscriberForm';
import PaymentSearch from './payment/PaymentSearch';
import WelcomeDash from './dashboard/WelcomeDash';
import MyProfile from './auth/MyProfile';
import InsightsOverview from './insights/InsightsOverview';
import ManagePDF from './pdfJournal/ManagePDF';
import ViewPDF from './pdfJournal/ViewPDF';
import PaymentForm from './payment/PaymentForm';
import AddPayments from './payment/AddPayments';
import AddPDF from './pdfJournal/AddPDF';
import SliderViewer from './ManageImage/SliderViewer';
import IssuePoster from './ImageEditor/issuePoster/IssuePoster';

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => ( <> <Preloader show={loaded ? false : true} /> <Component {...props} /> </> ) } />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  /*const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }*/

  //const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

 /* const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }*/

  return (
    <Route {...rest} render={props => (
      <>
        <Preloader show={loaded ? false : true} />
        <Sidebar />

        <main className="content">
          <Navbar />
          <Component {...props} />
         {/*} <Footer toggleSettings={toggleSettings} showSettings={showSettings} />*/}
        </main>
      </>
    )}
    />
  );
};

export default () => (
  <Switch>

    {/* auth */}
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
    <RouteWithSidebar exact path={Routes.ResetPassword.path} component={ResetPassword} />
    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />

    {/* pages */}
    <RouteWithSidebar exact path={Routes.Presentation.path} component={WelcomeDash} />
    <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={WelcomeDash} />
    <RouteWithSidebar exact path={Routes.MyProfile.path} component={MyProfile} />
    <RouteWithSidebar exact path={Routes.ImageViewer.path} component={ImageViewer} />

    {/*Subscribtion*/}
    <RouteWithSidebar exact path={Routes.Search.path} component={SearchPage} />
    <RouteWithSidebar exact path={Routes.AccountMgmt.path} component={AccountMgmt} />
    <RouteWithSidebar exact path={Routes.ManageSubscribers.path} component={ManageSubscription} />
    <RouteWithSidebar exact path={Routes.AddSubscriber.path} component={SubscriberForm} />

    {/*Journal*/}
    <RouteWithSidebar exact path={Routes.ManagePDF.path} component={ViewPDF} />
    <RouteWithSidebar exact path={Routes.AddPDF.path} component={AddPDF} />

    {/* Slider */}
    <RouteWithSidebar exact path={Routes.SliderViewer.path} component={SliderViewer} />
    <RouteWithSidebar exact path={Routes.IssuePoster.path} component={IssuePoster} />

    {/*Payments*/}
    <RouteWithSidebar exact path={Routes.AddPayments.path} component={AddPayments} />
    <RouteWithSidebar exact path={Routes.PaymentsReport.path} component={PaymentSearch} />

    {/*Insights*/}
    <RouteWithSidebar exact path={Routes.Insights.path} component={InsightsOverview} />

    <Redirect to={Routes.NotFound.path} />
  </Switch>
);
