import React from 'react'
import ImageLoader from '../../components/manageimage/ImageLoader'

export default function ImageViewer() {
  return (
    <div>ImageViewer
        <ImageLoader />
    </div>
  )
}
