
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { Auth } from "aws-amplify";
import { Alert  } from "reactstrap";
import {Helmet} from 'react-helmet-async';

import { Routes } from "../../routes";


export default function ForgotPassword () {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [alertDetails, setAlertDetails] = useState({style:"", open:false, message:""});

  const handleGenerateCode=async(event)=>{
    event.preventDefault();
    try{
        if(email===""){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Enter Registered Email Address!"
        }); 
        }else{
        await Auth.forgotPassword(email)
        .then(()=>{
            setCodeSent(true);
            setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message:"OTP has been sent to your registered Email address"
          }); 
        })
        .catch((error)=>{
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        });
    }
    }catch(error){
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      }); 
    }

  }

  const alertClose=()=>{
    setAlertDetails({
        ...alertDetails,
        style:"",
        open:false,
        message:""
    });
}

  const handleVerifyForgotPassword = async(event) =>{
    event.preventDefault();
    try{
        if(email==="" || code==="" || password===""){
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Enter all the details!"
          }); 
        }else{
        const username=email;
        const new_password=password;
        await Auth.forgotPasswordSubmit(username, code, new_password)
        .then(() => {
          setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message:"Password Changed Successfully"
        }); 
        })
        .catch(err => {
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + err.message
        }); 
        });
    }
    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    }
}


  return (
    <main>
    <Helmet>
      <title>Forgot Password - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="Forgot Password - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="Forgot Password - Admin Office - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
    </Helmet>

      <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link as={Link} to={Routes.Signin.path} className="text-gray-700">
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to sign in
            </Card.Link>
            </p>
            <Alert color={alertDetails.style} fade={true} isOpen={alertDetails.open} toggle={alertClose} >
             {alertDetails.message}
            </Alert>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3>Forgot your password?</h3>
                <p className="mb-4">Don't fret! Just type in your email and we will send you a code to reset your password!</p>
                <Form>
                  <div className="mb-4">
                    <Form.Label htmlFor="email">Your Email</Form.Label>
                    <InputGroup id="email">
                      <Form.Control required autoFocus disabled={codeSent} type="email" placeholder="john@company.com" onChange={(event)=>{setEmail(event.target.value)}}/>
                    </InputGroup>
                  </div>
                  {codeSent?<div><div className="mb-4">
                    <Form.Label htmlFor="code">Enter Code</Form.Label>
                    <InputGroup id="code">
                      <Form.Control required autoFocus type="text" onChange={(event)=>{setCode(event.target.value)}}/>
                    </InputGroup>
                  </div>
                  <div className="mb-4">
                    <Form.Label htmlFor="pwd">New Password</Form.Label>
                    <InputGroup id="pwd">
                      <Form.Control required autoFocus type="text" onChange={(event)=>{setPassword(event.target.value)}}/>
                    </InputGroup>
                  </div>
                  </div>:null}

                  {!codeSent?<Button variant="primary" type="submit" className="w-100" onClick={(event)=>{handleGenerateCode(event)}}>
                    Generate Code
                  </Button>:<Button variant="primary" type="submit" className="w-100" onClick={(event)=>{handleVerifyForgotPassword(event)}}>
                    Reset password
                  </Button>}
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
