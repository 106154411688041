import React, {useState, useEffect, useContext} from 'react'
import axios from 'axios';
import {Alert  } from "reactstrap";
import Preloader from "../../components/Preloader";
import { ProductContext } from '../../ProductProvider';
import { faEye, faEllipsisH, faCheck, faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { Button} from '@themesberg/react-bootstrap';
//import RegistrationForm from './RegistrationForm';
import { Button, Dropdown, Table, ButtonGroup } from '@themesberg/react-bootstrap';
//import FileAttachments from '../../components/attachments/FileAttachments';
//import AcademicInfo from './AcademicInfo';
//import OptedCourseDetails from './OptedCourseDetails';
import { Auth } from 'aws-amplify';
const config = require('../../config.json');

export default function ListSubscribers(props) {
  const {applicationDetails, setApplicationDetails, status} = props;
  const { alertClose, alertDetails, setAlertDetails } = useContext(ProductContext);
  const [loaded, setLoaded] = useState(false);
  //const [selectedItem, setSelectedItem]=useState('');
//  const [alertDetails, setAlertDetails] = useState({style:"", open:false, message:""});
  //const [collapsed, setCollapsed] = useState(true);
  
useEffect(()=>{
  //console.log("props : " , props);
  alertClose();
  fetchSubscribersDetails();
},[])


const fetchSubscribersDetails=async()=>{
  try{
      setLoaded(false);
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      const clientID="VJJournal";
      const applicationStatus=status;
      await axios.get(`${config.api.invokeUrl}/subscribers/status?clientID=${clientID}&subStatus=${applicationStatus}`,{
          headers: {
            Authorization: access_token,
            'x-api-key': config.api.key
          }}, 
      ).then((response)=>{
        console.log("Response : ", response);
          setApplicationDetails(response.data);
          setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: response.data.length + " - " + status + " Subscribers Found"
          });  
          //console.log(response);
          setLoaded(true);
      }).catch((error)=>{
          console.log(error);
          setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Error Occured - " + error.message
          }); 
          setLoaded(true);
          //setDispMsg(error.message);
      })
  
  }catch(error){
    console.log(error);
      setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      }); 
      setLoaded(true);
  }
}

  return (
    <>
    <Preloader show={loaded ? false : true} />
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
    {alertDetails.message}
    </Alert>
    {/*View*/}
   {/*} <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%'}}>
                <ModalHeader toggle={toggle}>
                </ModalHeader>
                <ModalBody>    
                    <RegistrationForm formType={"View"} profileDetails={profileDetails} setProfileDetails={setProfileDetails}/> 
                    <OptedCourseDetails formType={"View"} selectedCourse={profileDetails.selectedCourse} />
                    <AcademicInfo formType={"View"} form={profileDetails} setForm={setProfileDetails} />  
                    <FileAttachments idProof={idProof} certProof={certProof} />    
                </ModalBody>
                <ModalFooter>
        {' '}
        <Button onClick={toggle}>
            Close
        </Button>
        </ModalFooter>
  </Modal>*/}

    <Table responsive hover className="user-table align-items-center">
         <thead>
            <tr>
            <th>#</th>
            <th>Subscriber ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>End Date</th>
            <th>Category</th>
            {/*<th>Action</th>*/}
            </tr>
        </thead>
        <tbody>
        {applicationDetails.map((item, index)=>
        <tr key={index}>
        <th scope="row">{index+1}</th>
        <td>{item.subscriberID}</td>
        <td>{item.title}.{item.subscriberName}</td>
        <td>{item.emailID}</td>
        <td>{item.endDate}</td>
        <td>{item.categoryID}</td>
        {/*<td>
       <Dropdown as={ButtonGroup}>
        <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
          <span className="icon icon-sm">
            <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item   >
            <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
          </Dropdown.Item>
          <Dropdown.Item className="text-success" >
            <FontAwesomeIcon icon={faCheck} className="me-2" /> Approve
          </Dropdown.Item>
          <Dropdown.Item className="text-danger" >
            <FontAwesomeIcon icon={faCancel} className="me-2" /> Deny
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
        {/*<FontAwesomeIcon icon={faEye} className="me-2" onClick={(event)=>{handleApplicationView(event, item)}}/>
        <FontAwesomeIcon icon={faCheck} className="me-2" onClick={(event)=>{handleApplicationUpdate(event, item,"Approved")}}/>
        <FontAwesomeIcon icon={faCancel} className="me-2" onClick={(event)=>{handleApplicationUpdate(event, item,"Declined")}}/>
        
        <button onClick={(event)=>{handleProjectView(event, item)}}>View</button>
        <button onClick={(event)=>{handleProjectDelete(event, item)}}>Delete</button>
        <button onClick={(event)=>{handleProjectEdit(event, item)}}>Edit</button>
        </td>*/}
        </tr>)}
        </tbody>
        </Table>

</>
  )
}
