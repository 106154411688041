import React, { useState, useEffect } from 'react'
import axios from 'axios';
import ImageDisplay from './ImageDisplay';
const config = require('../../config.json');


export default function ImageLoader() {
    const [galleryImages, setGalleryImages] = useState('');
    const [dispMsg, setDispMsg] = useState('');

    useEffect(() => {
        fetchGalleryImages();
        
    }, []);

    const handleDeleteImage = async(event, imageName)=>{
        event.preventDefault();
        const imageid=imageName.substring(12,imageName.length);
        console.log("imageid : ", imageid);
        await axios.get(`${config.api.invokeUrl}/client/accounts/manage/gallery/${imageid}`,{
            header:{
                //Authorization: access_token,
                'x-api-key': config.api.key
            }
        }).then((response)=>{
            console.log(response);
            //setGalleryImages(response.data);
            setDispMsg(imageid + " - Deleted Successfully");
            fetchGalleryImages();
        }).catch((error)=>{
            //console.log(error.message);
            setDispMsg(error.message);
        })
    }

    const fetchGalleryImages =async()=>{
        try{
            console.log("Testing");
          //  const session = await Auth.currentSession();
          //  const access_token=session.idToken.jwtToken;
            const fileName='img/gallery';
            const bucketName="dev.idntica.com";
        await axios.get(`${config.api.invokeUrl}/imageviewer/manage?fileName=${fileName}&bucketName=${bucketName}`,{
            headers: {
             // Authorization: access_token,
              'x-api-key': config.api.key
            }}, 
        ).then((response)=>{
            console.log(response);
            setGalleryImages(response.data);
            setDispMsg(response.data.length + " - Images are found in your Gallery");
        }).catch((error)=>{
            //console.log(error.message);
            setDispMsg(error.message);
        })
    }catch(error){
        setDispMsg(error.message);
    }
}

    return (
        <div>
            {dispMsg}
            <div>
            {galleryImages.length>0? galleryImages.map((images)=><ImageDisplay 
            imageSrc={images} key={images} handleDeleteImage={handleDeleteImage}/>):
            <div>No Images found in your Image Gallery</div>} 
            </div>

        </div>
    )
}
